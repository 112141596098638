<template>
  <div class="yuepao" id="yuepao">
    <iframe :src="loufengUrl" frameborder="0" class="iframe" id="my_iframe"></iframe>
    <div class="loading_box" v-show="!isShow">
      <Loading />
    </div>
  </div>
</template>
<script>
import Loading from '@/components/Loading/index.vue';

export default {
  nema: 'yuePao',
  components: {
    Loading,
  },
  data() {
    return {
      loufengUrl: '',
      isShow: false,
    };
  },
  created() {
    // this.loufengUrl = this.$store.state.app.appConfig.louFengH5;
  },
  mounted() {
    this.loufengUrl = this.$store.state.app.appConfig.louFengH5;
    var iframe = document.querySelector('#my_iframe');
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        this.isShow = true;
      });
    } else {
      iframe.onload = () => {
        this.isShow = true;
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.yuepao {
  width: 100%;
  height: 100%;
  .loading_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }
}
</style>
